<template>
    <WitModal
        v-model="showModal"
        title="Confirm your choice"
        variant="warning"
        customClass="setup-card-modal"
        size="md"
        @input="emitInput"
    >
        <div class="setup-card-modal__text">
            <strong>Now you will be charged:</strong>
            <p>~{{ getRemainingCost() }}$ for the number of days remaining until the end of the month</p>
            <strong>Next payments:</strong>
            <p>125$ on the 1st day of each month</p>
            <strong>Other costs:</strong>
            <p>
                The cost for the consumption of our resources will be charged according to the pricing placed on our
                <a href="https://witbee.com/pricing" target="_blank">website</a> on the last day of each month
            </p>
            <hr />
            <p>By setting up this info you are activating your subscription. Are you sure?</p>
        </div>
        <b-row class="d-block text-center">
            <b-button variant="success" size="md" @click="finish">Yes</b-button>
            <span class="pad-buttons" />
            <b-button variant="danger" size="md" @click="close">No</b-button>
        </b-row>
    </WitModal>
</template>

<script>
import moment from 'moment'

import WitModal from '@/components/Modals/WitModal'

export default {
    components: {
        WitModal,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        step: {
            type: Object,
            default: () => null,
        },
    },
    data() {
        return {
            showModal: false,
        }
    },
    created() {
        this.showModal = this.value
    },
    watch: {
        value() {
            this.showModal = this.value
        },
    },
    methods: {
        getRemainingCost() {
            const now = moment()

            const startOfMonth = moment().startOf('month')
            const endOfMonth = moment().endOf('month')
            const hoursInMonth = endOfMonth.diff(startOfMonth, 'hours')

            const costPerHour = (125 / hoursInMonth).toFixed(2)

            const hoursTillEndOfMonth = endOfMonth.diff(now, 'hours')

            return (hoursTillEndOfMonth * costPerHour).toFixed(2)
        },

        emitInput() {
            this.$emit('input', this.showModal)
        },

        finish() {
            this.$emit('finish', this.step)
            this.close()
        },

        close() {
            this.showModal = false
            this.emitInput()
        },
    },
}
</script>

<style lang="scss">
.setup-card-modal {
    header {
        h5 {
            font-size: 0.9rem;
        }
    }

    .modal-body {
        .setup-card-modal__text {
            margin-bottom: 15px;
        }
    }
}
</style>
